import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useRef} from 'react'
import {isEventsInMembersInstalled} from '../../../../../../commons/selectors/installed-apps'
import {useWidgetState} from '../../../hooks/state-provider'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {getRibbonData} from '../../../selectors/events'
import {shouldShowCountdown} from '../../../selectors/layout'
import {isMembersVisible} from '../../../selectors/settings'
import classesV2 from '../../classes-v2.scss'
import {Countdown} from '../../countdown'
import {EventImage} from '../../event-image'
import {FullDateLocation} from '../../full-date-location'
import {LinkToPage} from '../../link-to-page'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import s from './on-image-item.scss'

interface OnImageItemProps {
  event: ExtendedEvent
  featuredWidget?: boolean
}

export const OnImageItem = ({event, featuredWidget = false}: OnImageItemProps) => {
  const container = useRef<HTMLDivElement>(null)
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const {
    allBreakpoints: {
      isListShortDateLocationVisible,
      isListFullDateLocationVisible,
      isListCountdownEnabled,
      isListImageEnabled,
    },
  } = useVisibilityStyles()
  const membersEnabled = useWidgetState(isEventsInMembersInstalled)
  const showMembers = useWidgetState(state => isMembersVisible(state, event))
  const hasRibbon = useWidgetState(state => getRibbonData(state, event).visible)
  const showCountdown = shouldShowCountdown(event)

  return (
    <div ref={container} className={classNames(s.container, {[s.mobile]: isMobile, [s.featured]: featuredWidget})}>
      {isListImageEnabled() && (
        <div className={s.backgroundImage}>
          <EventImage event={event} opacityFallback backgroundFallback />
        </div>
      )}
      <div className={s.innerContainer}>
        <div className={s.eventInfo}>
          {hasRibbon && <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />}
          {isListCountdownEnabled() && showCountdown && (
            <div className={s.countdown}>
              <Countdown event={event} />
            </div>
          )}
          <div className={s.title}>
            <LinkToPage allowExternal event={event}>
              {event.title}
            </LinkToPage>
          </div>
          {isListShortDateLocationVisible() && (
            <div className={s.shortDateLocation}>
              <ShortDateLocation
                event={event}
                dateClassName={classesV2.eventDate}
                locationClassName={classesV2.eventLocation}
              />
            </div>
          )}
          {isListFullDateLocationVisible() && (
            <div className={s.fullDateLocation}>
              <FullDateLocation
                event={event}
                dateClassName={classesV2.eventDate}
                locationClassName={classesV2.eventLocation}
              />
            </div>
          )}
          {!!event.description && <div className={s.description}>{event.description}</div>}
          {showMembers && (
            <div className={s.members}>
              <Members event={event} />
            </div>
          )}
          {membersEnabled && <SocialBar event={event} t={t} className={s.socialBar} />}
        </div>
        <RsvpButton event={event} containerClassName={s.button} />
      </div>
    </div>
  )
}
