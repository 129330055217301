import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {DynamicStyle} from '../../../../../../commons/components/dynamic-style'
import {isEditor} from '../../../../../../commons/selectors/environment'
import stylesParams from '../../../../stylesParams'
import {useWidgetState} from '../../../hooks/state-provider'
import {useEventsList} from '../../../hooks/use-events-list'
import {getCompId} from '../../../selectors/component'
import {LoadMoreButton} from '../../load-more-button'
import {MainListTitle} from '../../main-list-title'
import {OnImageItem} from '../on-image-item'
import s from './on-image-list.scss'

export const OnImageList = () => {
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const {isMobile} = useEnvironment()
  const {experiments} = useExperiments()
  const editor = useWidgetState(isEditor)
  const compId = useWidgetState(getCompId)
  const styles = useStyles()

  const cardsPerRow = styles.get(stylesParams.cardsPerRow)
  const eventsCount = events.length
  const columnCount = Math.min(cardsPerRow, eventsCount, pageLimit)

  return (
    <div className={classNames(s.container, s.border)}>
      {editor && !isMobile && (
        <DynamicStyle namespace={compId}>
          {`.${s.grid} {
          --columns: ${columnCount};
        }`}
        </DynamicStyle>
      )}
      <MainListTitle />
      <ul className={classNames(s.grid, {[s.mobile]: isMobile})}>
        {events.map(event => (
          <OnImageItem key={event.id} event={event} />
        ))}
      </ul>
      {(hasMore || moreLoading) && (
        <div className={experiments.enabled(ExperimentNames.CornerRadiusAndShadow) ? null : s.loadMore}>
          <LoadMoreButton mobile={isMobile} hasMore={hasMore} moreLoading={moreLoading} />
        </div>
      )}
    </div>
  )
}
