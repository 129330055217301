import {useStyles} from '@wix/tpa-settings/react'
import {WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import React from 'react'
import stylesParams from '../../../stylesParams'
import {useWidgetState} from '../../hooks/state-provider'
import {getEvents} from '../../selectors/events'
import {OnImageItem} from './on-image-item'
import {OnImageList} from './on-image-list'

export const OnImage = () => {
  const {get} = useStyles()
  const featuredWidget = get(stylesParams.widgetType) === WIDGET_TYPE.FEATURED

  const [featuredEvent] = useWidgetState(getEvents)

  return featuredWidget ? <OnImageItem event={featuredEvent} featuredWidget /> : <OnImageList />
}
